import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from '../../shared/models/enum';
import { AuthenticationService } from '../services/authentication.service';
import { finalize } from 'rxjs';

export const jwtInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next) => {
    const authenticationService = inject(AuthenticationService);
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    if (authenticationService.currentUser.value) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                project: authenticationService.currentUser.value.project ?? '',
            }
        });
        authenticationService.showLoader();
    }
    return next(request).pipe(finalize(() => {
        authenticationService.hideLoader();
    }));
};